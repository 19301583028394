import {
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  VStack,
  Icon,
  Image,
  Tag,
  Text,
} from '@chakra-ui/react';
import FaveStar from './FaveStar';
import { FaCalendar, FaClock, FaGlobe, FaPeopleArrows } from "react-icons/fa";
import { FiUser, FiDollarSign } from "react-icons/fi";
import { tagSort } from '../utils/games';
import dayjs from 'dayjs';
import LocalTime from './LocalTime';
import RecurInfo from './RecurInfo';

const GameCard = ({ game, hosted, callback, distance }) => {
  const showMine = hosted && !game.venueHosted;
  let borderColor = showMine ? 'orange' : null;
  let boxShadow = null
  if (game.venueId && game.venueHosted) {
    borderColor = 'rgba(120,120,255,0.6)';
    boxShadow = '0 0 10px 2px ' + borderColor
  }

  if (game.host.trustxVerified && !borderColor) {
    borderColor = 'rgba(53, 251, 84, 0.6)'
  }

  var price = 0;
  try {
    price = parseFloat(game.price);
  } catch (e) {
    price = 0;
  }

  if (!game) return null;

  return <Card width="md" m={4} style={{ display: 'inline-block', overflow: 'hidden', cursor: 'pointer', boxShadow: boxShadow }} borderWidth={1} borderColor={borderColor} onClick={() => callback(game)}>
    <Box style={{ position: 'relative', height: '200px' }}>
      {game.image && <Image
        style={{ position: 'absolute', top: 0, left: 0 }}
        objectFit='cover'
        src={process.env.REACT_APP_IMAGE_BASE + game.image}
        alt={game.name}
        w="100%"
        h="200px"
      />}
      {showMine && <Text fontSize="sm" style={{ position: 'absolute', top: 0, left: '50%', marginLeft: '-3em', width: '6em', color: 'white', zIndex: 1, borderRadius: '0 0 15px 15px', textAlign: 'center' }} bg='orange'>Hosted</Text>}
      {(game.venueId != null && game.venueHosted) && <Text fontSize="sm" style={{ position: 'absolute', top: 0, left: '50%', marginLeft: '-4em', width: '8em', color: 'white', zIndex: 1, borderRadius: '0 0 15px 15px', textAlign: 'center' }} bg={borderColor}>Venue Event</Text>}
      {game.maxPlayers > 0 && <HStack style={{ position: 'absolute', top: 0, left: 0, width: '100%', backgroundImage: 'linear-gradient(rgba(0,0,0,1.0), rgba(0,0,0,0))', padding: '0.5rem 0.5rem 3.5rem 0.5rem' }} color="orange">
        <Box style={{ backgroundColor: 'white', borderRadius: '999999px', height: '1.5rem', width: '1.5rem', lineHeight: '1.5rem', textAlign: 'center', border: '1px solid orange' }}><Icon as={FiUser} /></Box>
        <Text color="orange" fontWeight="bold" fontSize="sm">{game.players?.length || 0} / {game.maxPlayers}</Text>
      </HStack>}
      <HStack style={{ position: 'absolute', top: 0, right: 0, padding: '0.5rem', borderRadius: '9999px 0 0 9999px', backgroundImage: 'linear-gradient(rgba(0,0,0,1.0), rgba(0,0,0,0))', padding: '0.5rem 0.5rem 3.5rem 0.5rem' }} color="orange">
        <Box style={{ backgroundColor: 'white', borderRadius: '999999px', height: '1.5rem', width: '1.5rem', lineHeight: '1.5rem', textAlign: 'center', border: '1px solid orange' }}><Icon as={FiDollarSign} /></Box>
        <Text color="orange" fontWeight="bold" fontSize="sm">{price ? price.toFixed(2) : 'Free'}</Text>
      </HStack>
    </Box>
    <CardBody justify="space-between">
      <Box>
        <HStack justify="space-between">
          <Text fontSize="lg" fontWeight="bold" textAlign={"left"}>{game.name}</Text>
          <FaveStar kind="game" id={game.id} />
        </HStack>
        <HStack justify="space-between">
          <Text fontSize="md" fontWeight="" textAlign={"left"}>{game.system}  {game.type && '(' + game.type.toUpperCase() + ')'}</Text>
          <Text fontSize="md" fontWeight="" textAlign={"right"}>Hosted by <i>{game.venueHosted ? game.venue.name : game.host.name}</i></Text>
        </HStack>
      </Box>
      <HStack justify="space-between" mt={4}>
        <Flex align="center" flexDir={["column", "row"]} gap={1}>
          <Icon as={FaCalendar} mr={0} style={{ verticalAlign: 'baseline' }} />
          <Text fontSize="sm">{dayjs().format('YYYY') === dayjs(game.date).format('YYYY') ? dayjs(game.date).format('M/D') : dayjs(game.date).format('M/D/YY')}</Text>
          <RecurInfo game={game} fontSize="sm" />
        </Flex>
        <Flex align="center" flexDir={["column", "row"]} gap={1}>
          <Icon as={FaClock} mr={0} style={{ verticalAlign: 'baseline' }} />
          <LocalTime fontSize="sm" time={game.startTime} tz={game.timezone} />
        </Flex>
        <Flex align="center" flexDir={["column", "row"]} gap={1}>
          <Icon as={game.inperson ? FaPeopleArrows : FaGlobe} mr={0} style={{ verticalAlign: 'baseline' }} />
          {!distance && <Text fontSize="sm">{!game.online ? (game.venueId > 0 ? 'In Store' : 'In Person') : 'Online'}</Text>}
          {distance ? <Text fontSize="sm" color="gray.500">{distance} miles away</Text> : null}
        </Flex>
      </HStack>
      <Flex direction="row" wrap="wrap" mt={2}>
        {game.tags.sort(tagSort).map((tag) => <Tag variant={tag.variant || 'ugc'} mr={2} mt={2} key={tag.name + tag.id} >#{tag.name}</Tag>)}
      </Flex>
    </CardBody>
  </Card >
}
export default GameCard;
