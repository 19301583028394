import {
    Box,
    Card,
    CardBody,
    HStack,
    Heading,
    VStack,
    Icon,
    Image,
    Text,
    SimpleGrid,
} from '@chakra-ui/react';
import Avatar from './Avatar';
import FaveStar from './FaveStar';
import { FaClock, FaStore, FaMapPin, FaPhone, FaEnvelope, FaLink, FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { BsPerson, BsDiscord } from "react-icons/bs";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { openStatus, linkify } from '../utils/venues';
import Linkify from 'react-linkify';

dayjs.extend(customParseFormat);


const VenueCard = ({ venue, callback }) => {

    const status = () => {
        return openStatus(venue);
    };


    const formatHour = (hour) => {
        return hour ? dayjs(hour, 'HH:mm').format('h:mm A') : '';
    };

    let borderColor = null;
    let boxShadow = null;
    if (venue.isPartner) {
        borderColor = 'rgba(255,215,0,0.6)';
        boxShadow = '0 0 10px 2px ' + borderColor
    }

    return <Card width="xl" m={4} style={{
        display: 'inline-block',
        overflow: 'hidden',
        boxShadow: boxShadow,
        borderColor: borderColor,
        position: 'relative'
    }} onClick={() => callback(venue)}>
        {venue.banner && <Box style={{ position: 'relative', height: '200px' }}>
            <Image
                style={{ position: 'absolute', top: 0, left: 0 }}
                objectFit='cover'
                src={venue.banner}
                alt={venue.name + ' banner'}
                w="100%"
                h="200px"
            />
        </Box>}
        {venue.isPartner && <Text fontSize="sm" style={{ position: 'absolute', top: 0, left: '50%', marginLeft: '-3em', width: '6em', color: 'white', zIndex: 1, borderRadius: '0 0 15px 15px', textAlign: 'center', backgroundColor: borderColor, boxShadow: boxShadow }} >Partner</Text>}

        <CardBody style={{ color: 'white' }}>
            <HStack justify="space-between">
                <Heading size="md">{venue.name}</Heading>
                <Heading size="md"><FaveStar id={venue.id} kind="venue" /></Heading>
            </HStack>
            <HStack justify="" align="start" color="muted">
                <VStack mr={2} w="100px" align="center">
                    <Avatar avatar={venue.image} icon={FaStore} />
                    {venue.facebookLink && <Text mt={2} style={{ cursor: 'pointer' }}>
                        <Link onClick={(e) => e.stopPropagation()} target="_blank" to={linkify(venue.facebookLink)}>
                            <Icon as={FaFacebook} boxSize={9} color="white" />
                        </Link>
                    </Text>}
                    {venue.twitterLink && <Text mt={2} style={{ cursor: 'pointer' }}>
                        <Link onClick={(e) => e.stopPropagation()} target="_blank" to={linkify(venue.twitterLink)}>
                            <Icon as={FaTwitter} boxSize={9} color="white" />
                        </Link>
                    </Text>}
                    {venue.instagramLink && <Text mt={2} style={{ cursor: 'pointer' }}>
                        <Link onClick={(e) => e.stopPropagation()} target="_blank" to={linkify(venue.instagramLink)}>
                            <Icon as={FaInstagram} boxSize={9} color="white" />
                        </Link>
                    </Text>}
                    {venue.discordLink && <Text mt={2} style={{ cursor: 'pointer' }}>
                        <Link onClick={(e) => e.stopPropagation()} target="_blank" to={linkify(venue.discordLink)}>
                            <Icon as={BsDiscord} boxSize={9} color="white" />
                        </Link>
                    </Text>}

                </VStack>
                <VStack align="start" ml={2}>
                    <Text><Icon as={FaMapPin} mr={2} color="white" />{venue.address}</Text>
                    <Text><Icon as={BsPerson} mr={2} color="white" />{venue.contact}</Text>
                    <Text><Icon as={FaPhone} mr={2} color="white" />{venue.phone}</Text>
                    {venue.email && <Link to={`mailto:${venue.email}`}><HStack ><Icon as={FaEnvelope} color="white" /><Text>{venue.email}</Text></HStack></Link>}
                    {venue.website &&
                        <Link onClick={(e) => e.stopPropagation()} target="_blank" to={linkify(venue.website)} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                            <Text style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}><Icon as={FaLink} mr={2} color="white" />{venue.website?.replace(/https?:\/\//, '')}</Text>
                        </Link>
                    }
                    <Box mt={2} w="100%">
                        <Text fontWeight="bold" color="white"><Icon as={FaClock} mr={2} color="white" /> Hours ({status()})</Text>
                        <SimpleGrid columns={4} templateColumns="1fr 1fr 1fr">
                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => {
                                if (!venue[`${day}Open`]) {
                                    return null;
                                }
                                return <>
                                    <Text pr={2}>{day.charAt(0).toUpperCase() + day.substring(1)}:</Text>
                                    <Text textAlign={"right"}>{formatHour(venue[`${day}Open`])} - </Text>
                                    <Text textAlign={"right"}>{formatHour(venue[`${day}Close`])}</Text>
                                </>
                            })}
                        </SimpleGrid>
                    </Box>
                </VStack>
            </HStack>
            <Box mt={2} className="linkify">
                <Text fontWeight="bold" color="white">Description</Text>
                <Linkify>
                    <Text style={{ whiteSpace: 'pre-wrap' }} color="muted">{venue.description}</Text>
                </Linkify>
            </Box>
        </CardBody>
    </Card >
}
export default VenueCard;
