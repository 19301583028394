import {
    Icon,
} from '@chakra-ui/react';
import { FaStar, FaRegStar } from "react-icons/fa";
import { useQuery } from 'react-query';
import api from "../utils/api";


const FaveStar = ({ kind, id }) => {
    const favoritesQuery = useQuery('favorites', () => api('user/me/favorites'));
    const starred = favoritesQuery.data && favoritesQuery.data.filter((f) => f.kind == kind && f.itemId == id).length > 0;

    const onClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (starred) {
            await api('user/me/favorites', { kind: kind, itemId: id }, 'DELETE');
        } else {
            await api('user/me/favorites', { kind: kind, itemId: id }, 'POST');
        }
        favoritesQuery.refetch();
    };
    return <Icon
        as={starred ? FaStar : FaRegStar}
        color="white"
        cursor="pointer"
        onClick={onClick}
    />
};

export default FaveStar;
