import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Flex,
  Heading,
  VStack,
  Icon,
  Text,
  Image,
  HStack,
  Link,

  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,

  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { FaPlus, FaDownload } from "react-icons/fa";
import Header from '../../components/Header'
import { QRCodeCanvas } from 'qrcode.react';
import Avatar from '../../components/Avatar'
import useUserState from "../../stores/user";
import api from "../../utils/api";
import { useQuery } from 'react-query';

const AccountPage = ({ checkVerification }) => {
  const { user, update } = useUserState((state) => state);
  const { verifying, setVerifying } = useState(false);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const userQuery = useQuery(['user', user.id], () => api('/users/' + user.id));
  const [avatar, setAvatar] = useState(userQuery.data?.avatar);

  const saveAvatar = async () => {
    const res = await api('user/me', { avatar }, 'POST');
    update({ ...user, avatar });
  }

  const downloadQR = () => {
    const canvas = document.getElementById("qr");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${user.username}-AdventureNexus.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const getVerified = async () => {
    api("/user/me/daon", null, "GET").then((res) => {
      document.location = res.data;
    });
  };

  const checkVerificationStatus = async () => {
    if (user.trustxVerified) return;
    try {
      api("user/me/daonresult", null, "GET").then((res) => {
        if (res.data) {
          update({ ...user, trustxVerified: true });
          navigate("/account");
        } else {
          if (checkVerification) setTimeout(checkVerificationStatus, 1000);
        }
      })
    } catch (e) { console.log(e); }
  }


  useEffect(() => {
    checkVerificationStatus();
  }, []);


  const avatars = [
    {
      section: "Dwarves", images: [
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-16-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-23-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-37-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-6-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-28-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-25-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-31-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-10-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-1-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-17-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-36-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-22-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-7-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-29-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-30-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-24-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-11-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-2-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-19-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-21-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-35-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-14-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-4-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-12-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-9-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-27-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-33-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-3-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-18-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-34-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-20-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-15-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-5-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-13-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-32-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-8-thumb.png",
        "/avatars/dwarves/female/blob/species-dwarves-female-blob-26-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-12-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-27-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-7-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-21-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-14-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-1-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-19-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-13-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-26-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-6-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-20-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-15-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-18-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-25-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-8-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-31-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-10-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-28-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-5-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-16-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-23-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-3-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-30-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-24-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-9-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-11-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-29-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-4-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-17-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-22-thumb.png",
        "/avatars/dwarves/males/blob/species-dwarves-males-blob-2-thumb.png",
      ]
    },
    {
      section: "Elves", images: [
        "/avatars/elves/females/blob/species-elves-females-blob-2-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-19-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-21-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-14-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-4-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-12-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-9-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-3-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-18-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-20-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-15-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-5-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-13-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-8-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-16-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-23-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-6-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-10-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-1-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-17-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-22-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-7-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-24-thumb.png",
        "/avatars/elves/females/blob/species-elves-females-blob-11-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-10-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-4-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-9-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-2-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-11-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-5-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-8-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-3-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-6-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-12-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-7-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-13-thumb.png",
        "/avatars/elves/males/blob/species-elves-males-blob-1-thumb.png",
      ]
    },
    {
      section: "Half-Orcs", images: [
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-15-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-20-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-18-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-13-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-6-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-14-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-21-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-19-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-1-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-12-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-7-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-22-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-17-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-2-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-11-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-9-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-24-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-4-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-23-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-16-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-3-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-10-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-8-thumb.png",
        "/avatars/half-orcs/females/blob/species-half-orcs-females-blob-5-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-14-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-35-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-21-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-19-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-6-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-33-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-27-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-12-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-15-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-20-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-34-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-18-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-7-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-26-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-32-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-13-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-1-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-37-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-23-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-16-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-9-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-4-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-10-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-31-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-25-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-2-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-28-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-22-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-36-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-17-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-8-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-5-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-11-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-24-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-30-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-3-thumb.png",
        "/avatars/half-orcs/males/blob/species-half-orcs-males-blob-29-thumb.png",
      ]
    },
    {
      section: "Half-Elves", images: [
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-12-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-9-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-4-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-21-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-14-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-2-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-19-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-13-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-8-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-5-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-20-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-15-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-3-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-18-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-25-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-10-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-6-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-16-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-23-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-24-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-11-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-7-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-17-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-22-thumb.png",
        "/avatars/half-elves/females/blob/species-half-elves-females-blob-1-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-10-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-6-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-11-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-1-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-7-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-2-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-12-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-4-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-9-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-13-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-3-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-5-thumb.png",
        "/avatars/half-elves/males/blob/species-half-elves-males-blob-8-thumb.png",
      ]
    },
    {
      section: "Tieflings", images: [
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-9-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-4-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-11-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-2-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-8-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-5-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-10-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-3-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-6-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-13-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-14-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-7-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-12-thumb.png",
        "/avatars/tieflings/females/blob/species-tieflings-females-blob-1-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-3-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-13-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-20-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-15-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-5-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-18-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-8-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-2-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-12-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-21-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-14-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-4-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-19-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-9-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-24-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-1-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-11-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-17-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-7-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-22-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-10-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-16-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-6-thumb.png",
        "/avatars/tieflings/males/blob/species-tieflings-males-blob-23-thumb.png",
      ]
    },
    {
      section: "Humans", images: [
        "/avatars/humans/females/blob/species-humans-females-blob-1-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-23-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-16-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-28-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-7-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-10-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-25-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-22-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-17-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-6-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-11-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-24-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-19-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-3-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-14-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-21-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-5-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-27-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-8-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-12-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-18-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-2-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-15-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-20-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-4-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-26-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-9-thumb.png",
        "/avatars/humans/females/blob/species-humans-females-blob-13-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-43-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-39-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-4-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-18-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-34-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-20-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-15-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-9-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-2-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-45-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-51-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-13-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-32-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-48-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-26-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-56-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-42-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-38-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-5-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-19-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-21-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-35-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-14-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-8-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-3-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-50-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-44-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-12-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-27-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-33-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-49-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-6-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-41-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-55-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-17-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-36-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-22-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-47-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-53-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-29-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-30-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-24-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-11-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-7-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-54-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-40-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-16-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-23-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-37-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-52-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-28-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-46-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-1-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-25-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-31-thumb.png",
        "/avatars/humans/males/blob/species-humans-males-blob-10-thumb.png",
      ]
    },
    {
      section: "Halflings", images: [
        "/avatars/halflings/male/blob/species-halflings-male-blob-46-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-9-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-52-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-28-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-10-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-31-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-4-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-25-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-40-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-54-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-2-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-37-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-23-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-16-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-53-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-29-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-8-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-47-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-11-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-24-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-30-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-5-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-55-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-41-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-22-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-3-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-36-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-17-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-44-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-50-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-33-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-6-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-49-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-27-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-12-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-19-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-42-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-38-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-56-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-14-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-35-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-21-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-51-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-45-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-26-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-32-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-48-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-7-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-13-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-18-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-57-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-43-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-39-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-15-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-20-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-1-thumb.png",
        "/avatars/halflings/male/blob/species-halflings-male-blob-34-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-17-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-22-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-36-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-41-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-24-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-6-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-30-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-11-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-29-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-16-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-37-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-1-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-23-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-40-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-31-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-25-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-7-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-10-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-28-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-2-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-20-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-34-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-15-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-39-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-18-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-13-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-26-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-4-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-32-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-9-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-35-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-3-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-21-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-14-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-38-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-19-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-12-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-33-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-27-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-5-thumb.png",
        "/avatars/halflings/female/blob/species-halflings-female-blob-8-thumb.png",
      ]
    },
    {
      section: "Gnomes", images: [
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-3-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-26-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-32-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-13-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-5-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-18-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-39-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-8-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-15-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-20-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-34-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-2-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-33-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-27-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-12-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-4-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-19-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-38-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-9-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-14-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-35-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-21-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-1-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-29-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-11-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-24-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-30-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-7-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-22-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-36-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-17-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-28-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-10-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-31-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-25-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-40-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-6-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-37-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-23-thumb.png",
        "/avatars/gnomes/females/blob/species-gnomes-females-blob-16-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-23-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-37-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-8-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-16-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-40-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-5-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-10-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-25-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-31-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-28-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-46-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-3-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-36-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-9-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-22-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-17-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-41-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-4-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-11-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-30-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-24-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-47-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-2-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-29-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-14-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-21-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-35-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-19-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-38-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-7-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-42-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-27-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-49-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-33-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-12-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-50-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-1-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-44-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-15-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-34-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-20-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-18-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-39-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-6-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-43-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-48-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-32-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-26-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-13-thumb.png",
        "/avatars/gnomes/males/blob/species-gnomes-males-blob-45-thumb.png",
      ]
    },
    {
      section: "Dragonborns", images: [
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-9-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-4-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-23-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-16-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-10-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-2-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-8-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-22-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-5-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-17-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-11-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-3-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-19-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-14-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-6-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-21-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-12-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-18-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-15-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-20-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-7-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-1-thumb.png",
        "/avatars/dragonborns/females/blob/species-dragonborns-females-blob-13-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-5-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-28-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-10-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-8-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-31-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-25-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-3-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-40-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-37-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-23-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-16-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-29-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-4-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-11-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-24-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-9-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-30-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-2-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-41-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-22-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-36-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-17-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-7-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-33-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-27-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-12-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-19-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-38-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-42-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-1-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-14-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-35-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-21-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-6-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-26-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-32-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-13-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-18-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-39-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-15-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-20-thumb.png",
        "/avatars/dragonborns/males/blob/species-dragonborns-males-blob-34-thumb.png",
      ]
    },
  ];

  return <Box>
    <Header />
    <Container >
      <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
        <HStack justify="space-between" w="100%">
          <Box style={{ position: 'relative' }} mb={8}>
            <Avatar size={150} avatar={avatar} onClick={onOpen} />
            <Text
              onClick={onOpen}
              style={{
                position: 'absolute',
                bottom: -10,
                right: -10,
                cursor: 'pointer',
                backgroundColor: 'var(--chakra-colors-lightNavy)',
                borderRadius: '50%',
                border: '2px solid white',
                width: '30px',
                height: '30px',
                lineHeight: '30px',
                fontSize: '15px',
                textAlign: 'center',
                padding: 0,
                margin: 0,
              }}><Icon as={FaPlus} /></Text>
          </Box>
          <VStack onClick={downloadQR} style={{ cursor: 'pointer', textAlign: 'center' }}>
            <Box style={{ position: 'relative' }} mb={1} h={150} w={150}>
              <QRCodeCanvas
                id="qr"
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={document.location.origin + "/users/" + user.id}
              />
            </Box>
            <HStack><Icon boxSize={4} as={FaDownload} /><Text>Download</Text></HStack>
          </VStack>
        </HStack>
        <Grid templateColumns="repeat(2, 1fr)" gap={6} w="100%">
          {user.trustxVerified ? <Button variant="actionList" my={2} py={10} bg="orange" color="white" cursor="default">
            <VStack align="start" w="100%">
              <div>You're Verified!</div>
              <Text fontSize="0.8rem" color="white">You may host and Join games.</Text>
            </VStack>
          </Button>
            : <Button variant="actionList" onClick={getVerified} my={2} py={10}>
              <VStack align="start" w="100%">
                <div>Get Verified</div>
                <Text fontSize="0.8rem" color="muted">Verified users have access to more features.</Text>
              </VStack>
            </Button>}
          <Button variant="actionList" onClick={() => navigate("/account/setup")} my={2} py={10}>
            <VStack align="start" w="100%">
              <div>Edit Public Profile</div>
              <Text fontSize="0.8rem" color="muted">Set your name and social media links.</Text>
            </VStack>
          </Button>
          <Button variant="actionList" onClick={() => navigate("/games/calendar")} my={2} py={10}>
            <VStack align="start" w="100%">
              <div>My Calendar</div>
              <Text fontSize="0.8rem" color="muted">View games you're hosting or attending.</Text>
            </VStack>
          </Button>
          <Button variant="actionList" onClick={() => navigate("/favorites")} my={2} py={10}>
            <VStack align="start" w="100%">
              <div>My Favorites</div>
              <Text fontSize="0.8rem" color="muted">View games, users and venues that you've favorited.</Text>
            </VStack>
          </Button>
          {user.admin && <Button variant="actionList" onClick={() => navigate("/account/notifications")} my={2} py={12}>
            <VStack align="start" w="100%">
              <div>Notifications</div>
              <Text fontSize="0.8rem" color="muted">Manage your game notitications</Text>
            </VStack>
          </Button>}
          <Button variant="actionList" onClick={() => navigate("/account/invite")} my={2} py={10}>
            <VStack align="start" w="100%">
              <div>Invite Friends</div>
              <Text fontSize="0.8rem" color="muted">Invite your friends and fellow players to The Adventure Nexus.</Text>
            </VStack>
          </Button>
          <Button variant="actionList" onClick={() => navigate("/help")} my={2} py={12}>
            <VStack align="start" w="100%">
              <div>Help Center</div>
              <Text fontSize="0.8rem" color="muted">Contact support and get answers to frequently asked questions.</Text>
            </VStack>
          </Button>
        </Grid>
      </Flex>
    </Container>

    <Drawer placement={"bottom"} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', backgroundColor: 'var(--chakra-colors-lightNavy)' }}>
          <div style={{ padding: '1em', maxHeight: '50vh', width: '100%' }}>
            <VStack style={{ maxHeight: '100%', width: '100%' }}>
              <Heading size="md" mb={3} variant="subhead">Select a Profile Avatar</Heading>
              <Accordion allowToggle w="100%" style={{ overflow: 'auto' }}>
                {avatars.sort((a, b) => a.section.localeCompare(b.section)).map((section) => <AccordionItem w="100%">
                  <h2>
                    <AccordionButton _expanded={{ bg: 'orange', color: 'white' }}>
                      <Box as="span" flex='1' textAlign='left'>
                        {section.section}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel w="100%">
                    <Flex wrap="wrap" justifyContent="center">
                      {section.images.map((a) =>
                        <Image
                          style={{
                            height: '80px',
                            width: '80x',
                            margin: '1em',
                            opacity: a == avatar ? 1 : 0.8,
                            border: '2px solid ' + (a == avatar ? 'orange' : 'gray'),
                            display: 'inline-block',
                            cursor: 'pointer',
                            borderRadius: 10
                          }}
                          onClick={() => setAvatar(a)}
                          src={a}
                          key={a}
                        />
                      )}
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>)}
              </Accordion>
              <Button w="50%" onClick={() => { onClose(); saveAvatar(); }}>Set Image</Button>
              <Link onClick={() => { onClose(); setAvatar(user?.avatar); }}>Cancel</Link>
            </VStack>
          </div>
        </div>
      </DrawerContent>
    </Drawer>

  </Box >
}

export default AccountPage;
