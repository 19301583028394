import {
    React,
    useEffect,
    useState,
} from "react";
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Container,
    Flex,
    Icon,
    Heading,
    HStack,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Text,
    VStack,
} from '@chakra-ui/react';
import { FaWindowClose } from "react-icons/fa";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useQuery } from 'react-query'
import api from "../utils/api";

import useUserState from "../stores/user";
import Header from '../components/Header'
import Error from '../components/Error'
import PlayerCard from '../components/PlayerCard'



const GamePlayersPage = ({ }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const user = useUserState((state) => state.user);
    const { gameId } = useParams();
    const gameQuery = useQuery(['game', gameId], () => api('/games/' + gameId, false, 'QUERY'));
    const game = gameQuery.data;
    const playerRecord = game?.players.filter((p) => p.id == user.id);
    const myGame = game?.host.id == user.id;

    const acceptedPlayers = game?.players.filter((p) => p.approved) || [];
    const newPlayers = game?.players.filter((p) => !p.approved) || [];

    const acceptPlayer = async (player) => {
        const res = await api('games/' + gameId + '/approve', { playerId: player.id }, 'POST');
        if (res.error) {
            setError(res.error);
            return;
        }
        gameQuery.refetch();
    };

    const removePlayer = async (player) => {
        if (window.confirm('Click OK to remove player from game.')) {
            const res = await api('games/' + gameId + '/remove', { playerId: player.id }, 'POST');
            if (res.error) {
                setError(res.error);
                return;
            }
            gameQuery.refetch();
        }
    };


    const rejectPlayer = async (player) => {
        const res = await api('games/' + gameId + '/reject', { playerId: player.id }, 'POST');
        if (res.error) {
            setError(res.error);
            return;
        }
        gameQuery.refetch();
    };

    const show = game && (myGame || (playerRecord.length > 0 && playerRecord[0].approved));

    if (!show) {
        return <Box>Unauthorized</Box>
    }

    return <Box>
        <Header />
        <Box mx={4}><Link to={`/games/${gameId}`}>&lt; Back to Game</Link></Box>
        <Container variant="md" >
            <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
                <Heading>Attendees List</Heading>
                {error && <Error>{error}</Error>}
                <Tabs w="100%">
                    <TabList>
                        {myGame && <Tab w={myGame ? '50%' : '100%'}>ACCEPTED {acceptedPlayers.length}/{game?.maxPlayers}</Tab>}
                        {myGame && <Tab w="50%">NEW{!!newPlayers.length && <Text variant="badge">{newPlayers.length}</Text>}</Tab>}
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {acceptedPlayers.length ? acceptedPlayers.map((player) => <HStack>
                                <PlayerCard player={player} />
                                <a href="#" onClick={() => removePlayer(player)}><Icon as={FaWindowClose} /></a>
                            </HStack>
                            ) : <Card w="100%">
                                <CardBody>
                                    <Text>No accepted players yet.</Text>
                                </CardBody>
                            </Card>}
                        </TabPanel>
                        <TabPanel>
                            {newPlayers.length ? newPlayers.map((player) => <PlayerCard player={player} key={player.id}>
                                <HStack mt={2}>
                                    <Button onClick={() => acceptPlayer(player)}>Accept</Button>
                                    <Button ml={4} variant="secondary" onClick={() => rejectPlayer(player)}>Decline</Button>
                                </HStack>
                            </PlayerCard>)
                                : <Card w="100%">
                                    <CardBody>
                                        <Text>No new players.</Text>
                                    </CardBody>
                                </Card>}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </Container>
    </Box >
}



export default GamePlayersPage;
