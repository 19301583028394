import React, { useState } from 'react';
import {
  Icon,
  Text,
} from '@chakra-ui/react';

import { BsRepeat } from "react-icons/bs";

const RecurInfo = ({ game, ...props }) => {

  if (!game.recurring) return null;

  let text = '';
  if (game.recurEveryX == 1) {
    if (game.recurPeriod == 'day') {
      text = 'Daily';
    } else if (game.recurPeriod == 'week') {
      text = 'Weekly';
    } else if (game.recurPeriod == 'month') {
      text = 'Monthly';
    } else if (game.recurPeriod == 'year') {
      text = 'Yearly';
    }
  } else {
    text = `Every ${game.recurEveryX} ${game.recurPeriod}s`;

  }

  return (
    <Text {...props}>
      (<Icon as={BsRepeat} pt={1} /> {text})
    </Text>
  )
};
export default RecurInfo;
